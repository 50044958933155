@font-face {
  font-family: 'AudiType-ExtendedBold';
  src: url('./assets/fonts/AudiType-ExtendedBold/AudiType-ExtendedBold_4.03.woff2') format('woff2'),
    url('./assets/fonts/AudiType-ExtendedBold/AudiType-ExtendedBold_4.03.woff') format('woff'),
    url('./assets/fonts/AudiType-ExtendedBold/AudiType-ExtendedBold_4.03.ttf') format('truetype'),
    url('./assets/fonts/AudiType-ExtendedBold/AudiType-ExtendedBold_4.03.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-Normal';
  src: url('./assets/fonts/AudiType-Normal/AudiType-Normal_4.03.woff2') format('woff2'),
    url('./assets/fonts/AudiType-Normal/AudiType-Normal_4.03.woff') format('woff'),
    url('./assets/fonts/AudiType-Normal/AudiType-Normal_4.03.ttf') format('truetype'),
    url('./assets/fonts/AudiType-Normal/AudiType-Normal_4.03.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-ExtendedNormal';
  src: url('./assets/fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal_4.03.woff2') format('woff2'),
    url('./assets/fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal_4.03.woff') format('woff'),
    url('./assets/fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal_4.03.ttf') format('truetype'),
    url('./assets/fonts/AudiType-ExtendedNormal/AudiType-ExtendedNormal_4.03.otf') format('opentype');
}

@font-face {
  font-family: 'AudiType-Bold';
  src: url('./assets/fonts/AudiType-Bold/AudiType-Bold_4.03.woff2') format('woff2'),
    url('./assets/fonts/AudiType-Bold/AudiType-Bold_4.03.woff') format('woff'),
    url('./assets/fonts/AudiType-Bold/AudiType-Bold_4.03.ttf') format('truetype'),
    url('./assets/fonts/AudiType-Bold/AudiType-Bold_4.03.otf') format('opentype');
}

@font-face {
  font-family: 'AudiTypeVar-Normal';
  src: url('./assets/fonts/AudiTypeVar-Normal/AudiTypeVF_4.033.woff2') format('woff2'),
    url('./assets/fonts/AudiTypeVar-Normal/AudiTypeVF_4.033.ttf') format('truetype');
}

@font-face {
  font-family: 'VWHead-Bold';
  src: url('./assets/fonts/VWHeadWeb/VWHeadWeb-Bold.woff2') format('woff2'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Bold.woff') format('woff'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Bold.ttf') format('truetype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Bold.eot') format('embedded-opentype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Bold.svg') format('svg');
}

@font-face {
  font-family: 'VWHead-BoldItalic';
  src: url('./assets/fonts/VWHeadWeb/VWHeadWeb-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-BoldItalic.woff') format('woff'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-BoldItalic.eot') format('embedded-opentype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-BoldItalic.svg') format('svg');
}

@font-face {
  font-family: 'VWHead-ExtraBold';
  src: url('./assets/fonts/VWHeadWeb/VWHeadWeb-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-ExtraBold.woff') format('woff'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-ExtraBold.ttf') format('truetype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-ExtraBold.eot') format('embedded-opentype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-ExtraBold.svg') format('svg');
}

@font-face {
  font-family: 'VWHead-Light';
  src: url('./assets/fonts/VWHeadWeb/VWHeadWeb-Light.woff2') format('woff2'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Light.woff') format('woff'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Light.ttf') format('truetype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Light.eot') format('embedded-opentype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-ExtraBold.svg') format('svg');
}

@font-face {
  font-family: 'VWHead-Regular';
  src: url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.woff2') format('woff2'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.woff') format('woff'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.ttf') format('truetype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.svg') format('svg');
}

@font-face {
  font-family: 'VWHead-RegularItalic';
  src: url('./assets/fonts/VWHeadWeb/VWHeadWeb-RegularItalic.woff2') format('woff2'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.woff') format('woff'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.ttf') format('truetype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/VWHeadWeb/VWHeadWeb-Regular.svg') format('svg');
}

@font-face {
  font-family: 'VWText-Bold';
  src: url('./assets/fonts/VWTextWeb/VWTextWeb-Bold.woff2') format('woff2'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Bold.woff') format('woff'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Bold.ttf') format('truetype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Bold.eot') format('embedded-opentype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Bold.svg') format('svg');
}

@font-face {
  font-family: 'VWText-BoldItalic';
  src: url('./assets/fonts/VWTextWeb/VWTextWeb-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-BoldItalic.woff') format('woff'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-BoldItalic.ttf') format('truetype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-BoldItalic.eot') format('embedded-opentype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-BoldItalic.svg') format('svg');
}

@font-face {
  font-family: 'VWText-Light';
  src: url('./assets/fonts/VWTextWeb/VWTextWeb-Light.woff2') format('woff2'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Light.woff') format('woff'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Light.ttf') format('truetype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Light.eot') format('embedded-opentype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Light.svg') format('svg');
}

@font-face {
  font-family: 'VWText-Regular';
  src: url('./assets/fonts/VWTextWeb/VWTextWeb-Regular.woff2') format('woff2'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Regular.woff') format('woff'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Regular.ttf') format('truetype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Regular.eot') format('embedded-opentype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-Regular.svg') format('svg');
}

@font-face {
  font-family: 'VWText-RegularItalic';
  src: url('./assets/fonts/VWTextWeb/VWTextWeb-RegularItalic.woff2') format('woff2'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-RegularItalic.woff') format('woff'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-RegularItalic.ttf') format('truetype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-RegularItalic.eot') format('embedded-opentype'),
    url('./assets/fonts/VWTextWeb/VWTextWeb-RegularItalic.svg') format('svg');
}

.app {
  font-family: 'AudiType-Normal', Verdana, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.scroll-disable {
  overflow-y: hidden !important;
}
