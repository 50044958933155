$font-family-vw-head-bold: 'VWHead-Bold', Verdana, sans-serif;
$font-family-vw-head-bold-italic: 'VWHead-BoldItalic', Verdana, sans-serif;
$font-family-vw-head-extra-bold: 'VWHead-ExtraBold', Verdana, sans-serif;
$font-family-vw-head-light: 'VWHead-Light', Verdana, sans-serif;
$font-family-vw-head-regular: 'VWHead-Regular', Verdana, sans-serif;
$font-family-vw-head-regular-italic: 'VWHead-RegularItalic', Verdana, sans-serif;
$font-family-vw-text-bold: 'VWText-Bold', Verdana, sans-serif;
$font-family-vw-text-bold-italic: 'VWText-BoldItalic', Verdana, sans-serif;
$font-family-vw-text-light: 'VWText-Light', Verdana, sans-serif;
$font-family-vw-text-regular: 'VWText-Regular', Verdana, sans-serif;
$font-family-vw-text-regular-italic: 'VWText-RegularItalic', Verdana, sans-serif;

// defining break points
$screen-xs: 375px; // max-width is 375px
$screen-s: 720px; //  min-width is 376px and max-width is 720px
$screen-m: 960px; //  min-width is 721px and max-width is 960px
$screen-l: 1280px; //  min-width is 961px and max-width is 1280px   currently this is large breakpoint in myVW
$screen-xl: 1600px; //  min-width is 1281px and max-width is 1600px
$screen-xxl: 1920px; //  min-width is 1601px and max-width is 1920px
// 1921 and above are considered as xxxl screens

// Mixins
// Extra small devices
@mixin xs {
  @media (max-width: #{$screen-xs + 1px}) {
    @content;
  }
}

// Small devices
@mixin s {
  @media (min-width: #{$screen-xs + 1px}) and (max-width: #{$screen-s}) {
    @content;
  }
}

// Medium devices
@mixin m {
  @media (min-width: #{$screen-s + 1px}) and (max-width: #{$screen-m}) {
    @content;
  }
}

// Large devices
@mixin l {
  @media (min-width: #{$screen-m + 1px}) and (max-width: #{$screen-l}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-l + 1px}) and (max-width: #{$screen-xl}) {
    @content;
  }
}

// Extra Extra large devices
@mixin xxl {
  @media (min-width: #{$screen-xl + 1px}) and (max-width: #{$screen-xxl}) {
    @content;
  }
}

// Extra Extra Extra large devices
@mixin xxxl {
  @media (min-width: #{$screen-xxl + 1px}) {
    @content;
  }
}

// Extra small and small devices
@mixin xs-s {
  @media (max-width: #{$screen-s}) {
    @content;
  }
}

// from large to Extra Extra Extra large devices
@mixin l-xxxl {
  @media (min-width: #{$screen-m + 1px}) {
    @content;
  }
}

// Usage of mixins break points
// h3 {
//   color: rgb(0, 0, 0);
//   font-size: 20px !important;
//   font-family: $font-family-vw-head-bold;
//   font-weight: bold;
//   @include xs {
//     font-size: 12px !important;
//   }
//   @include s {
//     font-size: 16px !important;
//   }
//   @include m {
//     font-size: 18px !important;
//   }
//   @include l {
//     font-size: 20px !important;
//   }
//   @include xl {
//     font-size: 25px !important;
//   }
//   @include xxl {
//     font-size: 32px !important;
//   }
//   @include xxxl {
//     font-size: 42px !important;
//   }
// }
