@import '../../../../../styles/audi-variables.scss';
@import '../../../../../styles/vw6-variables.scss';

.logo-app {
  padding: 1px 0px 9px 50px;
  height: 64px;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.07), 0 0 2px 0 rgba(0, 0, 0, 0.05);
  @include xs{
    padding: 1px 0px 9px 20px;
  }
  @include s{
    padding: 1px 0px 9px 20px;
  }
  @include m{
    padding: 1px 0px 9px 20px;
  }
}


.logo-app.audi > .logo-container {
  height: 24px;
  float: left;
  margin-top: 20px;
  padding-left: 10px;
}

.logo-app.audi > .logo-container-finance {
  height: 24px;
  float: left;
  margin-top: 20px;
  padding-left: 10px;
}

.logo-app.vw > .logo-container, .logo-app.vw > .logo-container-finance {
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0px 0px 0px 60px;
  @include xs {
    padding: 0px 0px 0px 20px;
  }

  @include s {
    padding: 0px 0px 0px 20px;
  }

  @include m {
    padding: 0px 0px 0px 20px;
  }
  .c-logo__image {
    max-height: 64px !important;
    padding: 0.8em;  

    @include xs {
      padding: 1em;
    }
  
    @include s {
      padding: 1em;
    }
  
    @include m {
      padding: 1em;
    }
    @include l {
      padding: 1em;
    }  
  
  }

}