
.sidebar {
  position: fixed;
  margin-top: 40px !important;
  top: 325px;
  z-index: 10000;
}

.c-media-grid {
  display: block !important;
}

.c-media-grid__item .nav-item {
  text-decoration: none !important;
  border: 0px !important;
  padding-bottom: 10px !important;
  color: rgba(0, 0, 0, 0.7);
}

.sidebar-hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 18px !important;
  width: auto;
  height: 0px;
}

.c-media-grid__item .nav-item:hover {
  color: #000 !important;
  text-decoration: none !important;
}

.nav-item.active-nav {
  color: black;
  text-decoration: none;
}

.c-accordion {
  border-top: none !important;
}

.sidebar-image {
  margin-left: 10px;
}