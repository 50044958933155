@import '../../../../../styles/vw6-variables.scss';
@import '../../../../../styles/audi-variables.scss';

.flex-container {
  display: flex;
}


.tandc-sidebar-content {
  width: 25%;
  float: left;
  margin-left: 60px;
}

.tandc-detail-content {
  margin-left: 150px !important;
  margin-right: 37px !important;
}

.tandc-content {
  max-width: 75%;

  @include xs {
    max-width: none;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @include s {
    max-width: none;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }

  @include m {
    max-width: none;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
  }
}

.tandc-desktop {
  @include xs {
    display: none !important;
  }

  @include s {
    display: none !important;
  }

  @include m {
    display: none !important;
  }
}

.tandc-mobile {
  .c-accordion__title-icon-link {
    top: 24px;
    right: 8px;
    left: auto;
    position: absolute;
    width: 16px;
    height: 16px;
  }
}

.audi {
  .tandc-header-container {
    font-family: $font-family-audi-type-extended-normal !important;
    text-align: left;
    padding-top: 90px;
    padding-bottom: 90px;
    @include xs {
      display: none;
    }
  
    @include s {
      display: none;
    }
  
    @include m {
      display: none;
    }
  }
  .tandc-title-header {
    font-family: $font-family-audi-type-extended-normal !important;
    display: block !important;
  
    @include xs {
      display: none !important;
    }
  
    @include s {
      display: none !important;
    }
  
    @include m {
      display: none !important;
    }
  }
  p {
    font-family: $font-family-audi-type-normal;
    color: rgba(0, 0, 0, 0.77);
  }
}

.vw6 {
  .tandc-header-container {
    font-family: $font-family-vw-head-bold !important;
    text-align: left;
    padding-top: 90px;
    padding-bottom: 90px;
  
    @include xs {
      display: none;
    }
  
    @include s {
      display: none;
    }
  
    @include m {
      display: none;
    }
  }
  .tandc-title-header {
    display: block !important;
    // font-size: 28px !important;
    font-family: $font-family-vw-head-bold !important;
  
    @include xs {
      display: none !important;
    }
  
    @include s {
      display: none !important;
    }
  
    @include m {
      display: none !important;
    }
  }
  p {
    font-family: $font-family-vw-text-regular;
    color: rgba(0, 0, 0, 0.77);
  }
}

.tandc-title-subheader {
  font-size: 2rem;
}